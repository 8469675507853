<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- 

          STOCK OPNAME FIELD REQUIRED

         -->
        
        <!-- <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <v-dialog
            ref="dialog"
            v-model="modal_birt_date"
            :return-value.sync="form.checking_date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-input-group>
                <b-input-group-prepend>
                  <button
                    class="btn btn-secondary"
                    type="button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="fas fa-calendar"></i>
                  </button>
                </b-input-group-prepend>
                <b-form-input
                  id="input-trigger-modal-patient"
                  v-model="form.checking_date"
                  placeholder="(YYYY-MM-DD)"
                  @input="formaterDate"
                >
                </b-form-input>
              </b-input-group>
            </template>

            <v-date-picker
              v-if="modal_birt_date"
              v-model="form.checking_date"
              locale="id"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_birt_date = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.checking_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group> -->
        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <v-dialog
            ref="dialog"
            v-model="modal_birt_date"
            :return-value.sync="form.checking_date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-input-group>
                <b-input-group-prepend>
                  <button
                    class="btn btn-secondary"
                    type="button"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="fas fa-calendar"></i>
                  </button>
                </b-input-group-prepend>
                <b-form-input
                  id="input-trigger-modal-patient"
                  v-model="form.checking_date"
                  placeholder="(YYYY-MM-DD)"
                  @input="formaterDate"
                >
                </b-form-input>
              </b-input-group>
            </template>

            <v-date-picker
              v-if="modal_birt_date"
              v-model="form.checking_date"
              locale="id"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal_birt_date = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.checking_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input employee -->
        <!-- 
        <div class="row px-0">
          <div class="form-control"
             @click="$bvModal.show('modal-employee')"
          >
            {{display.employee_name}}
          </div>
        </div> -->
        <b-form-group
          id="input-group-trigger-modal-employee"
          label="Karyawan :"
          label-for="input-trigger-modal-employee"
        >
          <b-form-input
            id="input-trigger-modal-employee"
            v-model="display.employee_name"
            placeholder="Pilih karyawan"
            readonly
            @click="$bvModal.show('modal-employee')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.employee_id }}</small>
        </b-form-group>

        <!-- 

          END STOCK OPNAME FIELD REQUIRED

         -->

        <!-- 

          DETAIL STOCK OPNAME FIELD REQUIRED

         -->

        <b-form-group
          id="input-group-trigger-modal-inventory-multiple"
          label="Inventori:"
          label-for="input-trigger-modal-inventory-multiple"
        >
          <b-form-input
            id="input-trigger-modal-inventory-multiple"
            v-model="display.inventory_name"
            placeholder="Pilih Inventori"
            readonly
            @click="$bvModal.show('modal-inventory-multiple')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.inventory_id }}</small>
        </b-form-group>

        <div class="overflow-auto">
          <div class="bg-light" v-if="inventory_collection.length > 0">
            <table class="table" v-if="inventory_collection.length > 0">
              <tr>
                <th>Nama</th>
                <th>Stok Di Sistem</th>
                <th>Stok Fisik</th>
                <th>Selisih Stok</th>
                <th></th>
              </tr>
              <tr v-for="mdc in inventory_collection" :key="mdc.id">
                <td>{{ mdc.name }}</td>
                <td>{{ mdc.current_stock }}</td>
                <td>{{ mdc.checking_stock }}</td>
                <td>{{ mdc.difference_stock }}</td>
                <td>
                  <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover.left
                    title="Hapus Obat dan Alkes"
                    type="button"
                    @click="removeMedicine(mdc.id)"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- 

          END DETAIL STOCK OPNAME FIELD REQUIRED

         -->

        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/inventory/stock-opname')"
        >
          Batal
        </b-button>
      </b-form>

      <b-modal
        ref="my-modal"
        hide-footer
        title="Resep Obat"
        @hide="hideModalCancel"
      >
        <b-form @submit.stop.prevent="medicineFormOnsubmit">
          <!-- <div class="d-block text-center"> -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="inventory_collection_form.name"
              placeholder="Nama obat"
              readonly
            ></b-form-input>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-quantity"
            label="Kuantitas:"
            label-for="input-quantity"
          >
            <b-form-input
              id="input-quantity"
              v-model="inventory_collection_form.quantity"
              placeholder="Kuantitas"
              required
            ></b-form-input>
          </b-form-group> -->
          <!-- </div> -->
          <!-- Submit & Cancel button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="hideModalCancel"
          >
            Cancel
          </b-button>
        </b-form>
      </b-modal>

      <ModalEmployee
        @chosenEmployee="setEmployee"
        @submitedEmployee="setEmployee"
      />

      <ModalInventory
        @chosenItem="setInventoryDetail"
        :selectedItem="inventory_collection"
        :checkbox="true"
        :isSortcut="true"
        :isAbjad="true"
      />
    </div>
  </v-app>
</template>

<script>
import ModalInventory from "@/component/general/ModalInventoryMultiple.vue";
import ModalEmployee from "@/component/general/ModalEmployee.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    form: Object,
    route: String,
    purpose: {
      type: String,
      default: "add",
    },
  },

  components: {
    ModalEmployee,
    ModalInventory,
  },

  data() {
    return {
      error: {
        name: "",
        blood_type_id: null,
        age: null,
        birt_date: null,
        last_donation_date: "",
        phone: "",
        gender: null,
        quantity: null,
        email: "",
        id_card_number: null,
        address: "",
      },
      modal_birt_date: false,

      // medicines
      inventory_collection_form: {
        id: "",
        name: "",
        current_stock: "",
        checking_stock: "",
        difference_stock: "",
      },

      inventory_collection: [],
      coll_medicine_str: "",

      display: {
        inventory_name: "",
        employee_name: "",
      },
    };
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.checking_date =
          this.form.checking_date + "-";
      }
      if (val.length == 7) {
        this.form.checking_date =
          this.form.checking_date + "-";
      }
    },
    
    setEmployee(value) {
      this.form.employee_id = value.id;
      this.display.employee_name = value.name;
      this.$bvModal.hide("modal-employee");
    },

    async setPrescription(value) {
      // if(this.isPackagePrescription == false){
      console.log("tangkap", value);
      if (this.inventory_collection.length > 0) {
        let a;
        for (a = 0; a < this.inventory_collection.length; a++) {
          if (this.inventory_collection[a].id == value.id) {
            this.inventory_collection.splice(a, 1);
            if (this.inventory_collection.length == 0) {
              this.display.inventory_name = "";
              break;
            } else if (this.inventory_collection.length > 0) {
              this.displayInventoryName(this.inventory_collection);
              break;
            }
            break;
          } else if (a == this.inventory_collection.length - 1) {
            // this.$bvModal.hide("modal-medicine");
            console.log("value", value);
            await this.inventory_id;
            this.inventory_id = value.id;
            console.log("inventory_id", this.inventory_id);
            // this.inventory_collection_form.id = "";
            this.inventory_collection_form.name = "";
            this.inventory_collection_form.current_stock = "";
            this.inventory_collection_form.checking_stock = "";
            this.inventory_collection_form.difference_stock = "";
            this.setInventory(value.id);
          }
        }
      } else {
        // this.$bvModal.hide("modal-medicine");
        console.log("value", value);
        await this.inventory_id;
        this.inventory_id = value.id;
        console.log("inventory_id", this.inventory_id);
        // this.inventory_collection_form.id = "";
        this.inventory_collection_form.name = "";
        this.inventory_collection_form.current_stock = "";
        this.inventory_collection_form.checking_stock = "";
        this.inventory_collection_form.difference_stock = "";
        this.setInventory(value.id);
      }
      // }
    },

    async setInventoryDetail(value) {
      if (this.inventory_collection.length > 0) {
        let a;
        for (a = 0; a < this.inventory_collection.length; a++) {
          if (this.inventory_collection[a].id == value.id) {
            this.inventory_collection.splice(a, 1);
            if (this.inventory_collection.length == 0) {
              this.display.inventory_name = "";
              break;
            } else if (this.inventory_collection.length > 0) {
              this.displayInventoryName(this.inventory_collection);
              break;
            }
            break;
          } else if (a == this.inventory_collection.length - 1) {
            // this.$bvModal.hide("modal-medicine");
            console.log("value", value);
            await this.inventory_id;
            this.inventory_id = value.id;
            console.log("inventory_id", this.inventory_id);
            // this.inventory_collection_form.id = "";
            this.inventory_collection_form.name = "";
            this.inventory_collection_form.current_stock = "";
            this.inventory_collection_form.checking_stock = "";
            this.inventory_collection_form.difference_stock = "";
            this.setInventory(value.id);
          }
        }
      } else {
        // this.$bvModal.hide("modal-medicine");
        console.log("value", value);
        await this.inventory_id;
        this.inventory_id = value.id;
        console.log("inventory_id", this.inventory_id);
        // this.inventory_collection_form.id = "";
        this.inventory_collection_form.name = "";
        this.inventory_collection_form.current_stock = "";
        this.inventory_collection_form.checking_stock = "";
        this.inventory_collection_form.difference_stock = "";
        this.setInventory(value.id);
      }
    },

    setInventory(id) {
      ApiService.get("inventories/" + id).then((response) => {
        console.log("inventories", response.data.data);
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.inventory_collection_form.name = response.data.data.name;
        this.inventory_collection_form.id = response.data.data.id;
        this.inventory_collection_form.current_stock =
          response.data.data.inventory_remaining_stock;
        this.inventory_collection_form.checking_stock = 0;
        this.inventory_collection_form.difference_stock = 0;

        this.setInventorySubmit();
        this.medicineFormOnsubmit();
        // this.$refs["my-modal"].show();
      });
    },

    displayInventoryName(medicine) {
      if (medicine.length == 0) {
        this.display.inventory_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.inventory_name = str;
      }
    },

    removeMedicine($id) {
      console.log("remove batch", $id);
      let a;
      for (a = 0; a < this.inventory_collection.length; a++) {
        if (this.inventory_collection[a].id == $id) {
          this.inventory_collection.splice(a, 1);
          console.log("hasil", this.inventory_collection);
        }
      }
      this.displayInventoryName(this.inventory_collection);
    },

    medicineFormOnsubmit() {
      // this.inventory_collection_form.total_price =
      //   parseInt(this.inventory_collection_form.quantity) *
      //   parseInt(this.inventory_collection_form.sales_price);
      // this.inventory_collection_form.total_price = parseInt(
      //   this.inventory_collection_form.total_price
      // ).toLocaleString("id-ID");
      // this.inventory_collection_form.sales_price = parseInt(
      //   this.inventory_collection_form.sales_price
      // ).toLocaleString("id-ID");

      this.inventory_collection_submit = true;
      let clone = { ...this.inventory_collection_form };
      this.inventory_collection.push(clone);

      console.log("hasil", this.inventory_collection);
      this.displayInventoryName(this.inventory_collection);
      // this.hideModal();
    },

    async hideModalCancel() {
      if (this.inventory_collection_submit == false) {
        console.log("tidak tekan submit");
        // this.inventory_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setInventorySubmit();
    },

    setInventorySubmit() {
      this.inventory_collection_submit = false;
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    getDisplayData() {
      if (this.form.employee_id != "") {
        this.display.employee_name = this.form.employee_name;
      }
    },

    async getItem() {
      let response = await module.paginate(
        `inventory-detail-stock-opname/by-stock/${this.$route.params.id}`,
        `?page=1&page_size=2000`
      );
      let pagination = response.meta.pagination;
      // this.totalRows = pagination.total;
      this.coll_medicine = response.data;
    },

    async formOnsubmit() {
      let inventory = JSON.stringify(this.inventory_collection);
      this.form.inventory = inventory;
      console.log(this.form, "==================== FORM ===================");
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/inventory/stock-opname");
      }
    },
  },

  mounted() {
    this.getDisplayData();
    if(this.purpose == "edit"){
      this.getItem();
    }
    // this.getAge(new Date().toISOString().substr(0, 10))
  },

  watch: {
    form: function (newVal, oldVal) {
      if (this.purpose == "edit") {
        this.form = this.form;

        // this.display.inventory_name = this.form.inventory_name
      }
    },
  },
};
</script>

<style>
</style>