<template>
  <div class="row">

    <div class="col-md-12">
      <Card :title="'Tambah Stok Opname'">
        <template v-slot:body>
          <Form
            :form="form"
            :route="'inventory-stock-opname'"
          />
        </template>
      </Card>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Form from "@/component/inventory/StockOpnameForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      form: {
        checking_date:"",
        employee_id:"",
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inventori", route: "/inventory/list" },
      { title: "Stok Opname", route: "/inventory/stock-opname" },
      { title: "Tambah" },
    ])
  },

}
</script>

<style>
</style>